@import 'src/styles/vars'; @import 'src/styles/colors'; @import 'src/styles/header-underline'; @import 'src/styles/headers';@import 'src/styles/mappy-bp'; @import 'src/styles/decoratives'; @import 'src/styles/text-icon';
.join {
  background-color: $color__green--light;
  padding: 68px 0 72px;

  h2 {
    color: #ffffff;
    font-size: 32px;
    line-height: 1.25;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 32px;

    @include bp(tablet) {
      font-size: 48px;
      line-height: 1.08;
      margin-bottom: 32px;
    }

    @include bp(desktop-large) {
      font-size: 52px;
      text-align: left;
    }

    @include bp(desktop-wide) {
      margin-bottom: 40px;
    }
  }

  @include bp(tablet) {
    padding: 96px 0;
  }

  @include bp(desktop-large) {
    padding: 80px 0 96px;
  }

  @include bp(desktop-wide) {
    padding: 96px 0 96px;
  }
}

.join__container {
  padding-top: 0;
  padding-bottom: 0;
}

.join__formfooter {
  margin-top: 32px;
  margin-bottom: 56px;

  button {
    width: 100%;

    @include bp(tablet) {
      max-width: 269px;
    }
  }

  @include bp(tablet) {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  @include bp(desktop-large) {
    margin-top: 24px;
    margin-bottom: 0;
    text-align: left;
  }
}

.join__list {
  list-style: none;
  margin: 0;
  padding: 0;

  @include bp(tablet) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    row-gap: 72px;
    column-gap: 176px;
  }

  @include bp(desktop-large) {
    column-gap: 64px;
  }

  @include bp(desktop-wide) {
    row-gap: 70px;
    column-gap: 80px;
  }
}

.join__item {
  margin-bottom: 72px;

  &:last-child {
    margin-bottom: 0;
  }

  p {
    color: #ffffff;
    font-size: 20px;
    line-height: 1.4;
    margin-top: 32px;
    margin-bottom: 0;

    @include bp(tablet) {
      font-size: 20px;
    }

    @include bp(desktop-large) {
      margin-top: 24px;
    }

    @include bp(desktop-wide) {
      font-size: 20px;
      line-height: 1.2;
    }
  }

  @include bp(tablet) {
    align-items: center;
    margin-bottom: 0;
  }

  @include bp(desktop-large) {
    text-align: left;
  }

  @include bp(desktop-wide) {
    // max-width: 320px;
  }
}

.join__tick {
  @include bp(tablet) {
  }
}

.join__error {
  color: #fff;
  background: red;
  padding: 10px;
  width: 100%;
  font-size: 20px;
  margin: 20px 0;
  border-radius: 4px;
}

.join__successbutton {
  background: none;
  border: none;
  padding: 0;
  margin: 20px 0 0 0;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  text-decoration: underline;
  cursor: pointer;
}

.join__successtitle {
  font-weight: 700;
  font-size: 44px;
  line-height: 58px;
  margin: 0 0 20px 0;
}

.join__listcontainer {
  display: flex;
  align-items: center;

  @include bp(desktop-wide) {
    align-items: flex-start;
  }
}

.join_side {
  position: relative;

  .join__listcontainer {
    @include bp(desktop-large) {
      display: none;
    }
  }

  .join__container {
    @include bp(desktop-large) {
      padding-left: 32px;
      padding-right: 32px;
    }

    @include bp(desktop-wide) {
      padding-left: 48px;
      padding-right: 48px;
    }
  }

  h2 {
    @include bp(desktop-large) {
      font-weight: 300;
      font-size: 32px;
      line-height: 1.25;
    }

    @include bp(desktop-wide) {
      font-size: 40px;
    }
  }

  @include bp(desktop-large) {
    padding: 40px 0 96px;
    position: sticky;
    top: 0;
  }

  @include bp(desktop-wide) {
    padding: 56px 0 104px;
  }

  &:before,
  &:after {
    @include bp(tablet) {
      content: "";
      position: absolute;
      top: 0;
      height: 100%;
      width: 2000px;
      background-color: $color__green--light;
    }
  }

  &:before {
    @include bp(tablet) {
      right: 100%;
    }

    @include bp(desktop-large) {
      display: none;
    }
  }

  &:after {
    @include bp(tablet) {
      left: 100%;
    }
  }
}

.join__radio {
  display: flex;
  margin-top: 32px;

  span {
    width: 16px;
    height: 16px;
    border: 2px solid #ffffff;
    position: relative;
    margin-right: 16px;
  }

  input {
    display: none;
  }

  input:checked ~ span:after {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    width: 6px;
    height: 6px;
    background-color: #ffffff;
    transform: rotate(45deg);
  }

  p {
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
    flex: 1;
    margin: 0;
  }

  @include bp(desktop-large) {
    margin-top: 24px;
  }
}

.join__input {
  input {
    @include bp(mobile) {
      font-size: 22px;
      line-height: 1.45;
    }
  }

  label {
    @include bp(mobile) {
      font-size: 22px;
      line-height: 1.45;
    }
  }
}

.join__formbtn_linkedin {
  @include bp(desktop-large) {
    margin-bottom: 6px;
  }
}
